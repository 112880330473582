import React from 'react';
import styled from 'styled-components';

const LayoutStyled = styled.div`
	display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  box-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.20);
  border-radius: 4px;
`;
const LinkStyled = styled.div`
  flex: 1 1 0px;
  padding: 1rem;
	display: flex;
  justify-content: center;
  font-size: 1.4rem;
	font-weight: 900;
  color: hsla(208, 100%, 50%, 1);
  background-color: hsla(208, 100%, 100%, 1);
`;

export default function MenuBar() {
    return (
      <LayoutStyled>
				<LinkStyled>
          Earn
        </LinkStyled>
        <LinkStyled>
          Challenge
        </LinkStyled>
        <LinkStyled>
          Activity
        </LinkStyled>
			</LayoutStyled>
    )
}
