import React, { useState, useEffect } from 'react'
import { Route } from 'react-router'
import styled from 'styled-components'
import { Provider, createClient, cacheExchange, fetchExchange } from 'urql'
import 'semantic-ui-css/semantic.min.css'

import './custom.css'
import { refreshTokenExchange } from "./exchanges/refreshTokenExchange"
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute'
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes'
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants'
import Home from './components/Home'
import EarnScreen from './routes/earn/EarnScreen'
import EarnNewMarbleScreen from './routes/earn/marble/EarnNewMarbleScreen'
import AuthorizedLayout from './components/app/AuthorizedLayout'

const LayoutStyled = styled.div`
	background-color: hsla(208, 100%, 98%, 1); 
`;

export default function App() {
  const [user, setUser] = useState({
    isAuthenticated: false,
    userName: null,
    token: null
  });

  const graphQLUrl = process.env.NODE_ENV === 'development' ? 'https://brodiedev.com:5005/graphql' : 'https://api.findmymarbles.com/graphql';
  const client = createClient({
    url: graphQLUrl,
    exchanges: [cacheExchange, refreshTokenExchange(), fetchExchange]
  });
    
  return (
    <Provider value={client}>
      <LayoutStyled>
      
        <Route exact path='/' component={Home} />

        <AuthorizeRoute component={AuthorizedLayout}>
          <AuthorizeRoute exact path='/earn' component={EarnScreen} />
          <AuthorizeRoute exact path='/earn/marble/:marbleId' component={EarnNewMarbleScreen} />  
        </AuthorizeRoute>
      
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      </LayoutStyled>
    </Provider>
  );
}