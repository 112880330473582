import React, { useState } from 'react';
import styled, {keyframes} from 'styled-components';

const LayoutStyled = styled.div`
	display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.20);
  border-radius: 4px;
  color: hsla(208, 100%, 100%, 1);
  background-color: hsla(208, 100%, 50%, 1);
`;
const BoxStyled = styled.div`
  flex: 1 1 0px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	font-size: 2rem;
  font-weight: 900;
  text-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.20);
  line-height: 2.5rem;
`;
const delayedFadeIn = keyframes`
0% {
  visibility: hidden;
  opacity: 0;
}
25% {

}
50% {
  visibility: hidden;
  opacity: 0;
}
75% {
  visibility: visible;
  opacity: 1;
}
`;
const plusOne = keyframes`
0% {
  visibility: visible;
  opacity: 1;
}
50% {
  transform: scale(2);
  color:yellow;
}
60% {
  visibility: hidden;
  opacity: 0;
}
75% {
  visibility: hidden;
  opacity: 0;
}
`;
const DayWrapperStyled = styled.div`
  position: relative;
`;
const DayStyled = styled.div`
  animation: ${delayedFadeIn} ${({animate})=>(animate?'2s':'0s')} forwards;
`;
const PlusOneStyled = styled.div`
  animation: ${plusOne} ${({animate})=>(animate?'2s':'0s')} forwards;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
`;

export default function Dashboard({marbleStatistics}) {
    const [todayCount, setTodayCount] = useState(2);
    const [todayJustAddedCount, setTodayJustAddedCount] = useState(0);

    return (
      <LayoutStyled>
				<BoxStyled>
					<div>Today</div>
          <DayWrapperStyled key={todayJustAddedCount} onClick={()=>setTodayJustAddedCount(todayJustAddedCount+1)} >
					  <DayStyled animate={todayJustAddedCount>0} >
              X {todayCount+todayJustAddedCount}
            </DayStyled>
            <PlusOneStyled animate={todayJustAddedCount>0} >
              + 1
            </PlusOneStyled>
          </DayWrapperStyled>
				</BoxStyled>
				<BoxStyled>
					<div>This Week</div>
					<div> X 7</div>
				</BoxStyled>
				<BoxStyled>
					<div>This Month</div>
					<div> X 22</div>
				</BoxStyled>
			</LayoutStyled>
    )
}
