import React, { useState, useEffect } from 'react'
import authService from './api-authorization/AuthorizeService'

import { Layout } from './Layout';

export default function Home() {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
      let f = async () => {
        const tempIsAuthenticated = await authService.isAuthenticated()
        setIsAuthenticated(tempIsAuthenticated)
      }
      f()
    }, []
  )

  return (
    <Layout><div>
      <h1>FindMyMarbles.com</h1>

      <p>Currently under development.</p>
      {/*
      
      <p>Life is better when you fill it with constructive activities. Stop losing your marbles by losing sight of your goals.</p>

      <p>Track the stuff that is important to you and challenge yourself to keep going! 
        FindMyMarbles.com is an app that makes this a breeze in 3 easy steps. 
        Create a challenge, earn some marbles, and track your progress with interactive dashboards.
        Add as little or as much information as your want. The key is to keep earning marbles!
      Available on the web, Android or iPhone.</p>

      <h2>1. Challenge yourself</h2>
      
      <p>Are there things you want to do but have a hard time staying on track?
        We got you covered. Creating a challenge is a simple and a highly effective motivator. 
        Pick the marble you want and how often you aim to earn it. 
      </p>

      <p>Get updates on your challenges via email, push notifications, or text messages.</p>

      <p>screenshot</p>

      <h2>2. Earn marbles</h2>

      <p>Did something marble worthy? Time to earn son! 
        Search for the marble you earned and fill out as little or as much detail as you want.
        Could be as simple as the "Taking a break" marble or as detailed as a full workout
        where you want to track all the details.
      </p>

      <p>screenshot</p>

      <h2>3. Track progress</h2>
      
      <p>We break down your progress by day, week, month, and year. Every screen you will see your totals.
        Drill down to see the breakdown with charts, trends, and history. 
      </p>

      <p>screenshot</p>

      <h2>...but wait there's more!</h2>

      <h2>Community</h2>

      <p>
        Join a community focused on positivity! Praise others for their accomplishments and receive recognition for yours.
        FindMyMarbles.com has a new take on social that removes the noise and just allows the gooey goodness. Fill your social feed
        with others that are full of life just like you. We don't all have our marbles found yet but let's motivate each other
        to find them.
      </p>

      <p>screenshot</p>
      
      <h2>Leaderboard</h2>

      <p>Compete against your friends and the large marble community. Do you have what it takes to be in the top ten?</p>

      <p>New challenges start every day.</p>

      <p>screenshot</p>

      <h2>Sync smart devices</h2>

      <p>Bring your data into FindMyMarbles.com for supported hardware.</p>

      <p>screenshot</p>

      <h2>Do</h2>

      <p>!greencheckmark Track the stuff that is important to you!</p>

      <h2>Don't</h2>

      <p>!redX Track stuff that is <b><i>assigned</i></b> to you.</p>*/}


      { isAuthenticated &&
        <ul>
          <li><a href='/earn'>Earn</a></li>               
        </ul>
      }

    </div></Layout>
  )
}
