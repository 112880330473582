import { mergeMap, pipe, fromPromise, map, fromValue } from "wonka";
import authService from '../components/api-authorization/AuthorizeService'

export const refreshTokenExchange = () => {
  let promise;
  return ({ forward }) => ops$ =>
    pipe(
      ops$,
      mergeMap(op => {
        return pipe(
          fromPromise(promise ? promise : (promise = authService.getAccessToken())),
          map(newToken => {
            promise = undefined;            
            const fetchOptions = {        
              headers: { 
                authorization: `Bearer ${newToken}`,
                origin: window.location.origin
              }
            };
            return { ...op, context: { ...op.context, token: newToken, fetchOptions } };
          })
        );        
      }),
      forward
    );
};