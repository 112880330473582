import React from 'react';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react'

const LayoutStyled = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid hsla(208, 95%, 95%, 1);
  box-shadow: 0 1px 2px hsla(208, 95%, 95%, 0.20);
  border-radius: 12px;
  margin-top: .5rem;
  padding: .5rem;
  background-color: hsla(208, 100%, 100%, 1);
`;
const TitleStyled = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: hsla(208, 80%, 30%, 1);
`;

const CircleStyled = styled.div`
  background: hsla(208, 100%, 50%, 1);
  border: 1.5px solid #0F1C3F;
  border-radius: 50%;
  box-shadow: 2px 2px 0 0 rgba(15, 28, 63, 0.125);
  height: 2em;
  width: 2em;
`

const LastUsedStyled = styled.div`
  font-size: 0.6rem;
  font-weight: 500;
  color: hsla(208, 80%, 30%, 1);
`

const MainStyled = styled.div`
  flex: 1 1 auto;
  padding-left: 0.5rem;
`

export default function Marble({marble, onClick}) {
    return (
      <LayoutStyled>
        <CircleStyled/>
        <MainStyled onClick={()=>onClick(marble.id)}>
          <TitleStyled>{marble.marbleName}</TitleStyled>
          { marble.marbleStats &&
            <LastUsedStyled>Last used {marble.marbleStats.lastEarnedOn}</LastUsedStyled>
          }
        </MainStyled>
        <div >
          <Dropdown>
            <Dropdown.Menu>
              <Dropdown.Item>Hide this marble</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item>Show history</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>  
        </div>

			</LayoutStyled>
    )
}
