export const ApplicationName = 'FindMyMarblesWeb';

export const QueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message'
};

export const LogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out'
};

export const LoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
  LoginFailed: 'login-failed',
  Profile: 'profile',
  Register: 'register'
};

const thisServerUrl = process.env.NODE_ENV === 'development' ? 'https://brodiedev.com:5007' : 'https://www.findmymarbles.com'
const authServerUrl = process.env.NODE_ENV === 'development' ? 'https://brodiedev.com:5001' : 'https://auth.findmymarbles.com'; // TODO: move to config

const prefix = `/authentication`;

export const ApplicationPaths = {
    DefaultLoginRedirectPath: '/',
    ApiAuthorizationClientConfigurationUrl: `${authServerUrl}/_configuration/${ApplicationName}`,
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
    LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
    Register: `${prefix}/${LoginActions.Register}`,
    Profile: `${prefix}/${LoginActions.Profile}`,
    LogOut: `${prefix}/${LogoutActions.Logout}`,
    LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
    LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
    IdentityRegisterPath: `${authServerUrl}/auth/Identity/Account/Register`,
    IdentityManagePath: `${authServerUrl}/auth/Identity/Account/Manage`
};

export const clientConfiguration = {
    authority: authServerUrl,
    client_id: "FindMyMarblesWeb",
    redirect_uri: `${thisServerUrl}/authentication/login-callback`,
    post_logout_redirect_uri: `${thisServerUrl}/authentication/logout-callback`,
    response_type: "code",
    scope: "FindMyMarblesApi openid profile"
};
