import React from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'urql'
import gql from 'graphql-tag'
import { Container } from 'semantic-ui-react'

import PageTitle from '../../components/PageTitle'
import Category from '../../components/Category'
import Marble from '../../components/Marble'
import Masonary from '../../components/Masonary'

const fetchCategories = gql`{
  categories {
    categoryName
    marbles {
      id
      marbleName
      picUrl
      createdByCurrentUser
      marbleStats {
        lastEarnedOn
        totalEarned
      }
    }
  }
}`

export default function EarnScreen() {
  const history = useHistory();
  const [result] = useQuery({
    query: fetchCategories,
  });
  
  const handleMarbleClick = (marbleId) => {
    history.push(`/earn/marble/${marbleId}`);
  }

  if (result.error) return <div>Error: {result.error.message}</div>;
  if (result.fetching) return <div>Loading...</div>;
  
  return (
    <Container>

      <PageTitle value='Earn' />

      <Masonary>
        { result.data.categories
        .filter(category => category.marbles.length > 0)
        .map(category =>
          
            <Category category={category} >
              {category.marbles && category.marbles.map(marble =>
                <Marble marble={marble} onClick={handleMarbleClick}/>
              )}
            </Category>  
          
        )}
      </Masonary>

      Don't see the marble you need? Create a new marble!

    </Container>
  )
}