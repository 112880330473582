import React from 'react';
import styled from 'styled-components';

const LayoutStyled = styled.div`
  padding: 0rem;
`;
const CategoryStyled = styled.div`
  font-size: 1.3rem;
  font-weight: 300;
  color: hsla(208, 18%, 56%, 1);
`;

export default function Category({category, children}) {
    return (
      <LayoutStyled>
				<CategoryStyled>{category.categoryName}</CategoryStyled>
        {children}
			</LayoutStyled>
    )
}
