import React from 'react'
import { useQuery } from 'urql'
import { Container } from 'semantic-ui-react'

import TitleBar from './TitleBar'
import Dashboard from './Dashboard'
import MenuBar from './MenuBar'
import Footer from './Footer'

export default function AuthorizedLayout({children}) {
  return (
    <Container>

      <TitleBar />

      <Dashboard />

      <MenuBar />

      {children}

      <Footer />

    </Container>
  )
}