import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useMutation } from 'urql'
import gql from 'graphql-tag'
import authService from '../../../components/api-authorization/AuthorizeService'
import {
	Segment,
	Header,
	Form,
	Grid,
	Input,
	Button,
	Divider
} from 'semantic-ui-react'
import { format } from 'date-fns'

const earnMarbleMutation = gql`
  mutation EarnMarble($marbleEarnedInput: MarbleEarnedInput!) {
  	earnMarble(marbleEarned: $marbleEarnedInput) {
			id
		}
	}
`;

export default function EarnNewMarbleScreen() {
	const { marbleId } = useParams();
	const history = useHistory()
	const [marbleEarned, setMarbleEarned] = useState({ marbleId, earnedOn: format(new Date(), 'yyyy-MM-dd hh:mm')	})
	const [earnMarbleResult, earnMarble] = useMutation(earnMarbleMutation)
	
	const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setMarbleEarned({
			...marbleEarned,
      [name]: value
    });

	}

	const handleFinishButtonClick = () => {
		saveEarnMarble()
	}

	const saveEarnMarble = async () => {		
		const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
		
		let userId = user.sub;
		let marbleEarnedInput = {
			...marbleEarned,
			userId
		}
		
		if (marbleEarnedInput.lengthOfTime)
		  marbleEarnedInput.lengthOfTime = parseInt(marbleEarnedInput.lengthOfTime)
		
		const variables = {	marbleEarnedInput	}

		const result = await earnMarble(variables)
		
		if (!result.error)
			history.push('/earn')
	}

	return (
		<Segment>
			<Header as='h1'>Earn New Marble</Header>

			<div>{marbleId}</div>
			<Form>
					<Grid>
					<Grid.Column width={2}/>
					<Grid.Column width={10} >
					<Form.Field >
						<label>Date and time</label>
						<Form.Input name='earnedOn' placeholder='Date and time' width={12} value={marbleEarned.earnedOn} onChange={handleInputChange} />
					</Form.Field>
					<Form.Field inline>
						<label>Location</label>
						<Input name='location' placeholder='Location' value={marbleEarned.location} onChange={handleInputChange} />
					</Form.Field>
					<Form.Field inline>
						<label>Total time</label>
						<Input name='lengthOfTime' placeholder='Total time' value={marbleEarned.lengthOfTime} onChange={handleInputChange} />
					</Form.Field>
					<Form.Field inline>
						<label>Highlights</label>
						<Input name='notes' placeholder='List out the highlights' value={marbleEarned.notes} onChange={handleInputChange} />
					</Form.Field>
					<Grid.Column width={4}/>	
					</Grid.Column>


					<Divider/>
					
					<Grid.Row columns={2}>
						<Grid.Column>
							<Button icon='cancel' content='Cancel' />
						</Grid.Column>
						<Grid.Column textAlign='right'>
							<Button 
								color='green'
								content='Finish'
								icon='trophy'
								onClick={handleFinishButtonClick} 
							/>
						</Grid.Column>
					</Grid.Row>

				</Grid>					
			</Form>

		</Segment>		
	)
}