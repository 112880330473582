import React from 'react';
import styled from 'styled-components';

const LayoutStyled = styled.div`
  padding: 1rem;
`;
const TitleStyled = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: hsla(208, 80%, 30%, 1);
`;

export default function TitleBar() {
    return (
      <LayoutStyled>
				<TitleStyled>FindMyMarblesWeb.com</TitleStyled>
			</LayoutStyled>
    )
}
