import React from 'react';
import styled from 'styled-components';

const PageTitleStyled = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: hsla(208, 80%, 30%, 1);
`;

export default function PageTitle({value}) {
  return <PageTitleStyled>{value}</PageTitleStyled>
}