import React, { useEffect, useRef, useState } from 'react'
import { useEventListener } from '../hooks/useEventListener'
import styled from 'styled-components'

const MasonryStyled = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${props => props.gap || `1em`};
`

const ColStyled = styled.div`
  display: grid;
  grid-gap: ${props => props.gap || `1em`};
`

const fillCols = (children, cols) => {
  children.forEach((child, i) => cols[i % cols.length].push(child))
}

export default function Masonry({ children, gap, minWidth = 500, ...rest }) {
  const ref = useRef()
  const [numCols, setNumCols] = useState(3)
  const cols = [...Array(numCols)].map(() => [])
  
  fillCols(children, cols)

  const resizeHandler = () =>
    setNumCols(Math.ceil(ref.current.offsetWidth / minWidth))
  useEffect(resizeHandler, [])
  useEventListener(`resize`, resizeHandler)

  return (
    <MasonryStyled ref={ref} gap={gap} {...rest}>
      {[...Array(numCols)].map((_, index) => (
        <ColStyled key={index} gap={gap}>
          {cols[index]}
        </ColStyled>
      ))}
    </MasonryStyled>
  )
}