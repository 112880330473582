import React from 'react';
import styled from 'styled-components';

const LayoutStyled = styled.div`
	padding: 2rem;
`;

const CopyrightStyled = styled.div`
  font-size: 0.8rem;
  color: #999999 
`;

export default function Footer() {
  return (
    <LayoutStyled>
      <CopyrightStyled>&copy; 2020 FindMyMarbles.com</CopyrightStyled>
    </LayoutStyled>
  )
}
